import { CssBaseline, ThemeProvider } from '@mui/material';
import { useContext } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppContextProvider, { AppContext } from './context/appContext';
import Router from './routes/Router';
import { themeDark, themeLight } from './shared/MUI/themes';

function App() {
  const ctx = useContext(AppContext);

  return (
    <AppContextProvider>
      <ThemeProvider theme={ctx.light ? themeLight : themeDark}>
        <BrowserRouter>
          <CssBaseline />
          <Router />
        </BrowserRouter>
      </ThemeProvider>
    </AppContextProvider>
  );
}

export default App;
