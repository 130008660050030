import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Modal, Box, Typography, Container, Button, Paper, TextField, IconButton, InputAdornment } from '@mui/material';
import { ChangeEvent, FunctionComponent, useContext, useState } from 'react';
import { AppContext } from '../../context/appContext';
import { updateAdminPassword, updateTesterPassword, updateTestReleases } from '../../services/ApiService';
import { roles } from '../../shared/enums/roles.enum';
import { colors } from '../../shared/MUI/colors';

interface NewPasswordModalProps {
  isModalOpen: boolean;
  onCancel: () => void;
}

const NewPasswordModal: FunctionComponent<NewPasswordModalProps> = ({ isModalOpen, onCancel }) => {
  const ctx = useContext(AppContext);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPassword(e.target.value);
    if (e.target.value !== '') {
      setIsPasswordValid(true);
    } else {
      setIsPasswordValid(false);
    }
  };

  const handleConfirmPasswordChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setConfirmPassword(e.target.value);
    if (e.target.value !== '') {
      setIsConfirmPasswordValid(true);
    } else {
      setIsConfirmPasswordValid(false);
    }
  };

  const handleUpdatePassword = () => {
    if (isPasswordValid && isConfirmPasswordValid && password === confirmPassword)
      if (ctx.user.role === roles.ADMIN) {
        updateAdminPassword(ctx.user.id, password, ctx.authHeader).then((res) => {
          onCancel();
          ctx.logInAdmin(ctx.user.email, password);
          window.location.reload();
        });
      }
    if (ctx.user.role === roles.TESTER) {
      updateTesterPassword(ctx.user.id, password, ctx.authHeader).then((res) => {
        onCancel();
        ctx.logInTester(ctx.user.email, password);
        window.location.reload();
      });
    }
  };

  return (
    <Modal
      open={isModalOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%'
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '80%',
          width: '60%',
          justifyContent: 'top',
          alignItems: 'center',
          backgroundColor: 'rgba(0,0,0,0.85)',
          paddingTop: 4,
          borderStyle: 'solid',
          borderWidth: 1,
          borderRadius: 3
        }}>
        <Typography
          id="modal-modal-title"
          sx={{
            fontFamily: 'Fresca',
            fontSize: '48px',
            fontWeight: '600',
            color: 'text.primary'
          }}>
          {'Change Password'}
        </Typography>
        {error && (
          <Typography
            sx={{
              fontFamily: 'Fresca',
              fontWeight: '600',
              fontSize: '20px',
              marginRight: 3,
              width: '20%',
              color: 'red'
            }}>
            {errorMessage}
          </Typography>
        )}
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            maxWidth: '100%'
          }}>
          <Paper
            elevation={20}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#00000000',
              justifyContent: 'center',
              textAlign: 'center',
              width: '700px',
              minWidth: '400px',
              height: '55vh',
              borderRadius: '8px'
            }}>
            <Container
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '30px',
                width: '100%',
                justifyContent: 'center',
                marginTop: '80px'
              }}>
              <Container
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '30px',
                  width: '100%',
                  justifyContent: 'center'
                }}></Container>
              <Box
                sx={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'row',
                  height: 80,
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <Typography
                  sx={{
                    fontFamily: 'Fresca',
                    fontWeight: '600',
                    fontSize: '20px',
                    marginRight: 3,
                    width: '20%'
                  }}>
                  New Password
                </Typography>
                <TextField
                  id="email"
                  value={password}
                  onChange={handlePasswordChange}
                  variant="outlined"
                  sx={{
                    width: '80%',
                    marginBottom: '30px',
                    marginTop: '30px',
                    color: 'info.main',
                    borderStyle: 'solid',
                    borderWidth: 1,
                    borderColor: 'text.primary',
                    borderRadius: 1,
                    input: { color: 'info.main' }
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'row',
                  height: 80,
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <Typography
                  sx={{
                    fontFamily: 'Fresca',
                    fontWeight: '600',
                    fontSize: '20px',
                    marginRight: 3,
                    width: '20%'
                  }}>
                  Confirm New Password
                </Typography>
                <TextField
                  id="displayName"
                  type={showPassword ? 'text' : 'password'}
                  variant="outlined"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} color="primary">
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  sx={{
                    width: '80%',
                    marginBottom: '30px',
                    marginTop: '30px',
                    color: 'info.main',
                    borderStyle: 'solid',
                    borderWidth: 1,
                    borderColor: 'text.primary',
                    borderRadius: 1,
                    input: { color: 'info.main' }
                  }}
                />
              </Box>
            </Container>
            <Container
              sx={{
                display: 'flex',
                flexDirection: 'row',
                height: '30px',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                marginTop: '150px',
                paddingBottom: '25px'
              }}>
              <Button variant="outlined" onClick={handleUpdatePassword}>
                <Typography
                  sx={{
                    fontFamily: 'Fresca',
                    fontWeight: '600',
                    fontSize: '30px',
                    color: colors.cyan
                  }}>
                  Update
                </Typography>
              </Button>
              <Button variant="outlined" onClick={onCancel}>
                <Typography
                  sx={{
                    fontFamily: 'Fresca',
                    fontWeight: '600',
                    fontSize: '30px',
                    color: 'text.secondary'
                  }}>
                  Cancel
                </Typography>
              </Button>
            </Container>
          </Paper>
        </Box>
      </Box>
    </Modal>
  );
};

export default NewPasswordModal;
