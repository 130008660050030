import { ThemeProvider, Typography } from '@mui/material';
import { Box, Container } from '@mui/system';
import { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { AppContext } from '../../context/appContext';
import { getAllTesters, requestToggleTesterIsActive } from '../../services/ApiService';
import UserActivationButton from '../UI/Buttons/UserActivationButton';
import Loader from '../UI/Loader';
import { themeLight, themeDark } from '../../shared/MUI/themes';

interface TestersProps {}

const Testers: FunctionComponent<TestersProps> = () => {
  const ctx = useContext(AppContext);

  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Tester ID',
      width: 80,
      editable: false,
      align: 'center'
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 300,
      editable: false
    },
    {
      field: 'displayName',
      headerName: 'Display Name',
      width: 150,
      editable: false
    },
    {
      field: 'isActive',
      headerName: 'Enabled?',
      type: 'boolean',
      width: 150,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <div onClick={() => requestToggleUserIsActive(cellValues.row.id, cellValues.value)}>
            <UserActivationButton userIsActive={cellValues.value} />
          </div>
        );
      }
    }
  ];

  useEffect(() => {
    if (isLoading) {
      getAllTesters(ctx.authHeader).then((res) => {
        const rows: any = [];
        res.data.map((tester: any) => {
          rows.unshift({
            id: tester.id,
            email: tester.email,
            displayName: tester.display_name,
            isActive: tester.enabled
          });
        });
        setRows(rows);
        setIsLoading(false);
      });
    }
  }, [ctx.authHeader, isLoading]);

  const requestToggleUserIsActive = useCallback(
    (id: number, currentIsActive: boolean) => {
      requestToggleTesterIsActive(id, currentIsActive, ctx.authHeader).then(() => setIsLoading(true));
    },
    [ctx.authHeader]
  );

  return (
    <ThemeProvider theme={ctx.light ? themeLight : themeDark}>
      <Container
        style={{ maxWidth: '100vw' }}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          height: '90vh',
          width: '100%',
          backgroundColor: 'background.default'
        }}>
        <Typography
          sx={{
            fontFamily: 'Fresca',
            fontSize: '50px',
            fontWeight: '600',
            color: 'text.primary',
            paddingLeft: '10%'
          }}>
          Testers
        </Typography>
        <Box
          sx={{
            display: 'flex',
            height: 600,
            width: '80%',
            alignItems: 'center',
            justifyContent: 'center',
            alignSelf: 'center',
            borderStyle: 'solid',
            borderWidth: 3,
            borderColor: 'text.primary',
            borderRadius: 2
          }}>
          {isLoading ? (
            <Loader height="100px" width="100px" />
          ) : (
            <DataGrid
              columns={columns}
              rows={rows}
              sx={{
                color: ctx.light ? 'text.secondary' : 'text.secondary',
                fontWeight: 600,
                borderColor: 'text.primary'
              }}
            />
          )}
        </Box>
        <Container
          sx={{
            display: 'flex',
            width: '100%',
            alignContent: 'end',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: '3vh'
          }}>
          <Typography
            sx={{
              fontFamily: 'Fresca',
              fontSize: '30px',
              fontWeight: '600',
              color: 'text.primary',
              marginRight: '1vw'
            }}>
            Powered by
          </Typography>
          <img src={require('../../assets/nf-logo.png')} alt="BLACKDOG_logo" width="35%" />
        </Container>
      </Container>
    </ThemeProvider>
  );
};

export default Testers;
