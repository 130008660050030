import React, { FunctionComponent } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Typography } from '@mui/material';

interface EditBuildButtonProps {
  isDisabled: boolean;
}

const EditBuildButton: FunctionComponent<EditBuildButtonProps> = ({ isDisabled }) => {
  return (
    <div>
      <Button
        sx={{
          borderStyle: 'solid',
          borderWidth: 2,
          borderColor: 'white',
          width: '100%',
          cursor: isDisabled ? 'default' : 'pointer',
          backgroundColor: isDisabled ? '#3d3d3d99' : 'rgba(0,76,131,0.6)',
          ':hover': {
            backgroundColor: isDisabled ? '#3d3d3d99' : 'rgba(0,76,131,0.93)'
          }
        }}>
        <EditIcon sx={{ color: 'text.secondary' }} />
        <Typography
          sx={{
            fontFamily: 'Fresca',
            fontStyle: 'normal',
            color: 'text.secondary'
          }}>
          Edit
        </Typography>
      </Button>
    </div>
  );
};

export default EditBuildButton;
