import { Box, Button, Container, InputLabel, MenuItem, Select, SelectChangeEvent, ThemeProvider, Typography } from '@mui/material';
import { FunctionComponent, SetStateAction, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../context/appContext';
import { getBugReportById, updateBugReportStatus } from '../../services/ApiService';
import { BugPriority } from '../../shared/enums/bug-priority.enum';
import { BugSeverity } from '../../shared/enums/bug-severity.enum';
import { BugReportStatus } from '../../shared/enums/bug-status.enum';
import { colors } from '../../shared/MUI/colors';
import { themeLight, themeDark } from '../../shared/MUI/themes';
import Loader from '../UI/Loader';

interface BugReportProps {}

interface Report {
  id: number;
  title: string;
  description: string;
  status: string;
  submitterId: number;
  submitterEmail: string;
  submitterName: string;
  release: string;
}

const BugReport: FunctionComponent = () => {
  const ctx = useContext(AppContext);
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [report, setReport] = useState<Report>();
  const [isChanged, setIsChanged] = useState(false);
  const [updateResponse, setUpdateResponse] = useState('');
  const [responseOK, setResponseOK] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState('SUBMITTED');
  const [selectedPriority, setSelectedPriority] = useState('NONE');
  const [selectedSeverity, setSelectedSeverity] = useState('NONE');

  useEffect(() => {
    params.id &&
      getBugReportById(parseInt(params.id), ctx.authHeader, true).then((res) => {
        setReport(res.data);
        setSelectedStatus(res.data.status);
        setSelectedPriority(res.data.priority);
        setSelectedSeverity(res.data.severity);
        setIsLoading(false);
      });
  }, [ctx.authHeader, isLoading, params.id]);

  const handleStatusChange = (event: { target: { value: SetStateAction<string> } }) => {
    setSelectedStatus(event.target.value);
    setIsChanged(true);
  };

  const handleSeverityChange = (event: { target: { value: SetStateAction<string> } }) => {
    setSelectedSeverity(event.target.value);
    setIsChanged(true);
  };

  const handlePriorityChange = (event: { target: { value: SetStateAction<string> } }) => {
    setSelectedPriority(event.target.value);
    setIsChanged(true);
  };

  const apiUpdateBugStatus = () => {
    report &&
      updateBugReportStatus(report.id, selectedStatus, selectedSeverity, selectedPriority, ctx.authHeader).then((res) => {
        setUpdateResponse(res.statusText);
        res.statusText === 'OK' ? setResponseOK(true) : setResponseOK(false);
        setIsChanged(false);
        setIsLoading(true);
      });
  };

  return (
    <>
      <ThemeProvider theme={ctx.light ? themeLight : themeDark}>
        <Container
          style={{ maxWidth: '100vw' }}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            height: '90vh',
            width: '100%',
            backgroundColor: 'background.default'
          }}>
          {isLoading ? (
            <Loader height="100px" width="100px" />
          ) : (
            <>
              <Container sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Ubuntu',
                      fontSize: '35px',
                      fontWeight: '600',
                      color: 'text.primary',
                      marginBottom: '5vh'
                    }}>
                    {report && report.title}
                  </Typography>
                </div>
              </Container>
              <Container sx={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Ubuntu',
                      fontSize: '20px',
                      fontWeight: '600',
                      color: 'text.secondary',
                      marginBottom: '5vh',
                      marginRight: '1vw'
                    }}>
                    {`Tester ID: `}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Ubuntu',
                      fontSize: '20px',
                      fontWeight: '600',
                      color: 'text.primary',
                      marginBottom: '5vh',
                      marginRight: '5vw'
                    }}>
                    {`${report?.id}`}
                  </Typography>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Ubuntu',
                      fontSize: '20px',
                      fontWeight: '600',
                      color: 'text.secondary',
                      marginBottom: '5vh',
                      marginRight: '1vw'
                    }}>
                    {`Tester e-mail: `}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Ubuntu',
                      fontSize: '20px',
                      fontWeight: '600',
                      color: 'text.primary',
                      marginBottom: '5vh',
                      marginRight: '15vw'
                    }}>
                    {`${report?.submitterEmail}`}
                  </Typography>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Ubuntu',
                      fontSize: '20px',
                      fontWeight: '600',
                      color: 'text.secondary',
                      marginBottom: '5vh',
                      marginRight: '1vw'
                    }}>
                    {`Tester Name: `}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Ubuntu',
                      fontSize: '20px',
                      fontWeight: '600',
                      color: 'text.primary',
                      marginBottom: '5vh',
                      marginRight: '15vw'
                    }}>
                    {`${report?.submitterName}`}
                  </Typography>
                </div>
              </Container>
              <Container sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: '2vh' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Ubuntu',
                      fontSize: '20px',
                      fontWeight: '600',
                      color: 'text.secondary',
                      marginRight: '1vw'
                    }}>
                    {`BUILD: `}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Ubuntu',
                      fontSize: '20px',
                      fontWeight: '600',
                      color: 'text.primary',
                      marginRight: '15vw'
                    }}>
                    {`${report?.release}`}
                  </Typography>
                </div>
                <div>
                  <InputLabel id="selectedSeverity">Severity</InputLabel>
                  <Select labelId="selectedSeverity-label" id="selectedSeverity" value={selectedSeverity} onChange={handleSeverityChange} sx={{ height: 50, width: 160 }}>
                    <MenuItem value={BugSeverity.LOW}>{BugSeverity.LOW}</MenuItem>
                    <MenuItem value={BugSeverity.SIGNIFICANT}>{BugSeverity.SIGNIFICANT}</MenuItem>
                    <MenuItem value={BugSeverity.SEVERE}>{BugSeverity.SEVERE}</MenuItem>
                    <MenuItem value={BugSeverity.CRITICAL}>{BugSeverity.CRITICAL}</MenuItem>
                  </Select>
                </div>
                <div>
                  <InputLabel id="selectedPriority">Priority</InputLabel>
                  <Select labelId="selectedPriority-label" id="selectedPriority" value={selectedPriority} onChange={handlePriorityChange} sx={{ height: 50, width: 160 }}>
                    <MenuItem value={BugPriority.LOW}>{BugPriority.LOW}</MenuItem>
                    <MenuItem value={BugPriority.MEDIUM}>{BugPriority.MEDIUM}</MenuItem>
                    <MenuItem value={BugPriority.HIGH}>{BugPriority.HIGH}</MenuItem>
                    <MenuItem value={BugPriority.IMMEDIATE}>{BugPriority.IMMEDIATE}</MenuItem>
                  </Select>
                </div>
                <div>
                  <InputLabel id="selectedStatus">Status</InputLabel>
                  <Select labelId="selectedStatus-label" id="selectedStatus" value={selectedStatus} onChange={handleStatusChange} sx={{ height: 50, width: 160 }}>
                    <MenuItem value={BugReportStatus.SUBMITTED}>{BugReportStatus.SUBMITTED}</MenuItem>
                    <MenuItem value={BugReportStatus.OPEN}>{BugReportStatus.OPEN}</MenuItem>
                    <MenuItem value={BugReportStatus.IN_PROGRESS}>{BugReportStatus.IN_PROGRESS}</MenuItem>
                    <MenuItem value={BugReportStatus.FIXED}>{BugReportStatus.FIXED}</MenuItem>
                    <MenuItem value={BugReportStatus.REJECTED}>{BugReportStatus.REJECTED}</MenuItem>
                  </Select>
                </div>
                <div>
                  {updateResponse !== '' && !isChanged && (
                    <Typography
                      sx={{
                        fontFamily: 'Fresca',
                        fontWeight: '600',
                        fontSize: '20px',
                        color: responseOK ? colors.green : 'red'
                      }}>
                      {updateResponse}
                    </Typography>
                  )}
                  {isChanged && (
                    <Button
                      variant="outlined"
                      onClick={apiUpdateBugStatus}
                      sx={{
                        height: '50px',
                        width: '130px',
                        backgroundColor: colors.violet_dark,
                        marginLeft: '5vw'
                      }}>
                      <Typography
                        sx={{
                          fontFamily: 'Fresca',
                          fontWeight: '600',
                          fontSize: '30px',
                          color: colors.cyan
                        }}>
                        Save
                      </Typography>
                    </Button>
                  )}
                </div>
              </Container>
              <Box
                sx={{
                  display: 'flex',
                  height: 600,
                  width: '80%',
                  padding: '3%',
                  alignSelf: 'center',
                  borderStyle: 'solid',
                  borderWidth: 3,
                  borderColor: 'text.secondary',
                  borderRadius: 2
                }}>
                {isLoading ? <Loader height="100px" width="100px" /> : <></>}
                {report && <Typography sx={{ color: 'text.primary' }}>{report.description}</Typography>}
              </Box>
              <Container
                sx={{
                  display: 'flex',
                  width: '100%',
                  alignContent: 'end',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingBottom: '3vh'
                }}>
                <Typography
                  sx={{
                    fontFamily: 'Fresca',
                    fontSize: '30px',
                    fontWeight: '600',
                    color: 'text.primary',
                    marginRight: '1vw'
                  }}>
                  Powered by
                </Typography>
                <img src={require('../../assets/nf-logo.png')} alt="BLACKDOG_logo" width="35%" />
              </Container>
            </>
          )}
        </Container>
      </ThemeProvider>
    </>
  );
};

export default BugReport;
