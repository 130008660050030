import { ChangeEvent, FunctionComponent, useContext, useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { Button, Container, Dialog, Modal, Paper, TextField, Typography } from '@mui/material';
import { colors } from '../../shared/MUI/colors';
import SaveIcon from '@mui/icons-material/Save';
import { AppContext } from '../../context/appContext';
import { deleteMajorRelease, deleteTestRelease, updateMajorReleases, updateTestReleases } from '../../services/ApiService';
import ConfirmModal from '../ConfirmModal';

interface EditBuildModalProps {
  isModalOpen: boolean;
  handleCloseModal: () => void;
  currentEdited: any;
}

const EditBuildModal: FunctionComponent<EditBuildModalProps> = ({ isModalOpen, handleCloseModal, currentEdited }) => {
  const ctx = useContext(AppContext);
  const [type, setType] = useState(currentEdited.name ? 'Test' : 'Major');
  const [name, setName] = useState(currentEdited.name ? currentEdited.name : currentEdited.version);
  const [link, setLink] = useState(currentEdited.link && currentEdited.link);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [confirmModalShown, setConfirmModalShown] = useState(false);

  const handleNameChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setName(e.target.value);
  };

  const handleLinkChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLink(e.target.value);
  };

  const handleSave = async () => {
    if (type === 'Test') {
      await updateTestReleases(currentEdited.id, { name, link }, ctx.authHeader).then((res) => {
        if (res.status === 200) {
          handleCloseModal();
        } else {
          console.log('error');
          setError(true);
          setErrorMessage('Error - are you owner of the build?');
        }
      });
    }
    if (type === 'Major') {
      updateMajorReleases(currentEdited.id, { version: name.split('.'), link }, ctx.authHeader).then((res) => {
        if (res.status === 200) {
          handleCloseModal();
        } else {
          setError(true);
          setErrorMessage('Error - are you owner of the build?');
        }
      });
    }
  };

  const handleDelete = () => {
    if (type === 'Test') {
      deleteTestRelease(currentEdited.id, ctx.authHeader).then((res) => {
        if (res.status === 200) {
          handleCloseModal();
        } else {
          setError(true);
          setErrorMessage('Error - are you owner of the build?');
        }
      });
    }
    if (type === 'Major') {
      deleteMajorRelease(currentEdited.id, ctx.authHeader).then((res) => {
        if (res.status === 200) {
          handleCloseModal();
        } else {
          setError(true);
          setErrorMessage('Error - are you owner of the build?');
        }
      });
    }
  };

  const confirm = () => {
    setConfirmModalShown(true);
  };

  return (
    <>
      {confirmModalShown && <ConfirmModal onConfirm={handleDelete} onCancel={() => setConfirmModalShown(false)} />}
      <Modal
        open={isModalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%'
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '80%',
            width: '60%',
            justifyContent: 'top',
            alignItems: 'center',
            backgroundColor: 'rgba(0,0,0,0.85)',
            paddingTop: 4,
            borderStyle: 'solid',
            borderWidth: 1,
            borderRadius: 3
          }}>
          <Typography
            id="modal-modal-title"
            sx={{
              fontFamily: 'Fresca',
              fontSize: '48px',
              fontWeight: '600',
              color: 'text.primary'
            }}>
            Edit Build
          </Typography>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap',
              maxWidth: '100%'
            }}>
            <Paper
              elevation={20}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#00000000',
                justifyContent: 'center',
                textAlign: 'center',
                width: '700px',
                minWidth: '400px',
                height: '55vh',
                borderRadius: '8px'
              }}>
              <Container
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '30px',
                  width: '100%',
                  justifyContent: 'center',
                  marginTop: '80px'
                }}>
                {error && (
                  <Typography
                    sx={{
                      fontFamily: 'Fresca',
                      fontWeight: '600',
                      fontSize: '20px',
                      marginRight: 3,
                      width: '20%',
                      color: 'red'
                    }}>
                    {errorMessage}
                  </Typography>
                )}
                <Container
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    height: '30px',
                    width: '100%',
                    justifyContent: 'center'
                  }}>
                  <Typography
                    sx={{
                      fontFamily: 'Fresca',
                      fontWeight: '600',
                      fontSize: '20px',
                      marginRight: 3,
                      marginLeft: 2,
                      width: '100%',
                      textAlign: 'start'
                    }}>
                    {`Build ID: ${currentEdited.id}`}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Fresca',
                      fontWeight: '600',
                      fontSize: '20px',
                      marginRight: 3,
                      marginLeft: 2,
                      width: '100%',
                      textAlign: 'start'
                    }}>
                    {`Owner: ${currentEdited.uploaderName}`}
                  </Typography>
                </Container>
                <Box
                  sx={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'row',
                    height: 80,
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                  <Typography
                    sx={{
                      fontFamily: 'Fresca',
                      fontWeight: '600',
                      fontSize: '20px',
                      marginRight: 3,
                      width: '20%'
                    }}>
                    Build Name
                  </Typography>
                  <TextField
                    id="buildname"
                    value={name}
                    onChange={handleNameChange}
                    variant="outlined"
                    sx={{
                      width: '80%',
                      marginBottom: '30px',
                      marginTop: '30px',
                      color: 'info.main',
                      borderStyle: 'solid',
                      borderWidth: 1,
                      borderColor: 'text.primary',
                      borderRadius: 1,
                      input: { color: colors.cyan }
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'row',
                    height: 80,
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                  <Typography
                    sx={{
                      fontFamily: 'Fresca',
                      fontWeight: '600',
                      fontSize: '20px',
                      marginRight: 3,
                      width: '20%'
                    }}>
                    Download Link
                  </Typography>
                  <TextField
                    id="link"
                    variant="outlined"
                    value={link}
                    onChange={handleLinkChange}
                    sx={{
                      width: '80%',
                      marginBottom: '30px',
                      marginTop: '30px',
                      color: 'info.main',
                      borderStyle: 'solid',
                      borderWidth: 1,
                      borderColor: 'text.primary',
                      borderRadius: 1,
                      input: { color: colors.cyan }
                    }}
                  />
                </Box>
              </Container>
              <Container
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '30px',
                  alignItems: 'center',
                  justifyContent: 'space-evenly',
                  marginTop: '150px',
                  paddingBottom: '25px'
                }}>
                <Button
                  variant="outlined"
                  onClick={handleSave}
                  sx={{
                    height: '80px',
                    width: '180px',
                    backgroundColor: colors.violet_dark
                  }}>
                  <Typography
                    sx={{
                      fontFamily: 'Fresca',
                      fontWeight: '600',
                      fontSize: '30px',
                      color: colors.cyan
                    }}>
                    Save
                  </Typography>
                </Button>
                <Button
                  variant="outlined"
                  onClick={confirm}
                  sx={{
                    backgroundColor: colors.raspberry_bg_light,
                    ':hover': {
                      backgroundColor: colors.raspberry
                    }
                  }}>
                  <Typography
                    sx={{
                      fontFamily: 'Fresca',
                      fontWeight: '600',
                      fontSize: '20px',
                      color: ctx.light ? 'white' : 'text.secondary'
                    }}>
                    Delete
                  </Typography>
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleCloseModal}
                  sx={{
                    backgroundColor: colors.violet_bg,
                    ':hover': {
                      backgroundColor: colors.violet_dark
                    }
                  }}>
                  <Typography
                    sx={{
                      fontFamily: 'Fresca',
                      fontWeight: '600',
                      fontSize: '20px',
                      color: ctx.light ? colors.cyan : 'text.secondary'
                    }}>
                    Cancel
                  </Typography>
                </Button>
              </Container>
            </Paper>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default EditBuildModal;
