import { Modal, Box, Typography, Button, Container } from '@mui/material';
import { FunctionComponent } from 'react';
import { colors } from '../shared/MUI/colors';

interface ConfirmModalProps {
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmModal: FunctionComponent<ConfirmModalProps> = ({ onConfirm, onCancel }) => {
  return (
    <>
      <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%'
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '20%',
            width: '30%',
            justifyContent: 'top',
            alignItems: 'center',
            backgroundColor: 'rgba(0,0,0,0.75)',
            paddingTop: 4,
            borderStyle: 'solid',
            borderWidth: 1,
            borderRadius: 3
          }}>
          <Typography sx={{ color: 'text.secondary' }}>Are You Sure?</Typography>
          <Container
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              marginTop: 3
            }}>
            <Button
              variant="outlined"
              onClick={onConfirm}
              sx={{
                backgroundColor: colors.raspberry_bg_light,
                ':hover': {
                  backgroundColor: colors.raspberry
                }
              }}>
              <Typography
                sx={{
                  fontFamily: 'Fresca',
                  fontWeight: '600',
                  fontSize: '20px',
                  color: 'text.secondary'
                }}>
                Delete
              </Typography>
            </Button>
            <Button variant="outlined" onClick={onCancel}>
              <Typography
                sx={{
                  fontFamily: 'Fresca',
                  fontWeight: '600',
                  fontSize: '20px',
                  color: 'text.secondary'
                }}>
                Cancel
              </Typography>
            </Button>
          </Container>
        </Box>
      </Modal>
    </>
  );
};

export default ConfirmModal;
