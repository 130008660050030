import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Button, Modal, ThemeProvider, Typography } from '@mui/material';
import { Box, Container } from '@mui/system';
import Loader from '../UI/Loader';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { getMajorReleases, getTestReleases } from '../../services/ApiService';
import { AppContext } from '../../context/appContext';
import EditBuildButton from '../UI/Buttons/EditBuildButton';
import EditBuildModal from './EditBuildModal';
import AddBoxIcon from '@mui/icons-material/AddBox';
import NewBuildModal from './NewBuildModal';
import { themeLight, themeDark } from '../../shared/MUI/themes';
import { roles } from '../../shared/enums/roles.enum';

export interface TestRelease {
  id: number;
  name: string;
  link: string;
  uploaderId: number;
  uploaderName: string;
  created_at: string;
}

export interface MajorRelease {
  id: number;
  version: string;
  link: string;
  uploaderId: number;
  uploaderName: string;
  created_at: string;
}

interface MajorReleaseQueryRes {
  id: number;
  version: number[];
  link: string;
  uploaderId: number;
  uploaderName: string;
  created_at: string;
}

interface BuildsProps {}

const Builds: FunctionComponent<BuildsProps> = () => {
  const [isMajorsLoading, setIsMajorsLoading] = useState(true);
  const [isTestersLoading, setIsTestersLoading] = useState(true);
  const ctx = useContext(AppContext);
  const [testRows, setTestRows] = useState<TestRelease[]>([]);
  const [majorRows, setMajorRows] = useState<MajorRelease[]>([]);

  const [isCreateModalOpen, setIsCreateModelOpen] = useState(false);
  const [createModalType, setCreateModalType] = useState<'Release' | 'Test' | ''>('');

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentEdited, setCurrentEdited] = useState('');

  useEffect(() => {
    if (isMajorsLoading) {
      if (ctx.user.role === roles.ADMIN) {
        let major_temp: any[] = [];
        let major_rows_temp: MajorRelease[] = [];
        getMajorReleases(ctx.authHeader, true).then((res) => {
          major_temp = res.data;
          major_temp.map((release: MajorReleaseQueryRes) => {
            major_rows_temp.unshift({
              id: release.id,
              link: release.link,
              uploaderId: release.uploaderId,
              uploaderName: release.uploaderName,
              created_at: new Date(release.created_at).toLocaleDateString(),
              version: release.version.join('.')
            });
          });
          setMajorRows(major_rows_temp);
          setIsMajorsLoading(false);
        });
      }
      if (ctx.user.role === roles.TESTER) {
        let major_temp: any[] = [];
        let major_rows_temp: MajorRelease[] = [];
        getMajorReleases(ctx.authHeader, false).then((res) => {
          major_temp = res.data;
          major_temp.map((release: MajorReleaseQueryRes) => {
            major_rows_temp.unshift({
              id: release.id,
              link: release.link,
              uploaderId: release.uploaderId,
              uploaderName: release.uploaderName,
              created_at: new Date(release.created_at).toLocaleDateString(),
              version: release.version.join('.')
            });
          });
          setMajorRows(major_rows_temp);
          setIsMajorsLoading(false);
        });
      }
    }
  }, [ctx.authHeader, isMajorsLoading]);

  useEffect(() => {
    if (isTestersLoading) {
      let test_temp: TestRelease[] = [];
      if (ctx.user.role === roles.TESTER) {
        getTestReleases(ctx.authHeader, false).then((res) => {
          res.data.map((result: { id: any; name: any; link: any; uploaderId: any; uploaderName: string; created_at: string | number | Date }) => {
            test_temp.unshift({
              id: result.id,
              name: result.name,
              link: result.link,
              uploaderId: result.uploaderId,
              uploaderName: result.uploaderName,
              created_at: new Date(result.created_at).toLocaleString('en-GB', {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
              })
            });
          });
          setTestRows(test_temp);
          setIsTestersLoading(false);
        });
      }
      if (ctx.user.role === roles.ADMIN) {
        getTestReleases(ctx.authHeader, true).then((res) => {
          res.data.map((result: { id: any; name: any; link: any; uploaderId: any; uploaderName: string; created_at: string | number | Date }) => {
            test_temp.unshift({
              id: result.id,
              name: result.name,
              link: result.link,
              uploaderId: result.uploaderId,
              uploaderName: result.uploaderName,
              created_at: new Date(result.created_at).toLocaleString('en-GB', {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
              })
            });
          });
          setTestRows(test_temp);
          setIsTestersLoading(false);
        });
      }
    }
  }, [ctx.authHeader, isTestersLoading]);

  const testColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Build ID',
      width: 80,
      editable: false,
      align: 'center'
    },
    {
      field: 'name',
      headerName: 'Build Name',
      width: 200,
      editable: false
    },
    {
      field: 'link',
      headerName: 'Build Download Link',
      flex: 1,
      editable: false
    },
    {
      field: 'uploaderName',
      headerName: 'Uploaded By',
      width: 150,
      editable: false
    },
    {
      field: 'created_at',
      headerName: 'Created at:',
      width: 200,
      editable: false
    },
    {
      field: 'uploaderId',
      headerName: 'Edit',
      type: 'boolean',
      width: 150,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <div
            onClick={(props) => {
              if (cellValues.row.uploaderId === ctx.user.id) {
                setCurrentEdited(cellValues.row);
                setIsEditModalOpen(true);
              }
            }}>
            <EditBuildButton isDisabled={cellValues.row.uploaderId !== ctx.user.id} />
          </div>
        );
      }
    }
  ];
  const majorColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Build ID',
      width: 80,
      editable: false,
      align: 'center'
    },
    {
      field: 'version',
      headerName: 'Build Version',
      width: 120,
      editable: false
    },
    {
      field: 'link',
      headerName: 'Build Download Link',
      flex: 1,
      editable: false
    },
    {
      field: 'uploaderName',
      headerName: 'Uploaded By',
      width: 150,
      editable: false
    },
    {
      field: 'created_at',
      headerName: 'Created at:',
      width: 200,
      editable: false
    },
    {
      field: 'uploaderId',
      headerName: 'Edit',
      type: 'boolean',
      width: 150,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <div
            onClick={(props) => {
              if (cellValues.row.uploaderId === ctx.user.id) {
                setCurrentEdited(cellValues.row);
                setIsEditModalOpen(true);
              }
            }}>
            <EditBuildButton isDisabled={cellValues.row.uploaderId !== ctx.user.id} />
          </div>
        );
      }
    }
  ];

  const handleCreateModalOpen = (type: 'Release' | 'Test') => {
    setCreateModalType(type);
    setIsCreateModelOpen(true);
  };

  const handleCloseModal = () => {
    setCurrentEdited('');
    setIsMajorsLoading(true);
    setIsTestersLoading(true);
    setIsEditModalOpen(false);
    setIsCreateModelOpen(false);
  };

  return (
    <>
      <ThemeProvider theme={ctx.light ? themeLight : themeDark}>
        {currentEdited !== '' && <EditBuildModal isModalOpen={isEditModalOpen} handleCloseModal={handleCloseModal} currentEdited={currentEdited} />}
        {isCreateModalOpen && <NewBuildModal isModalOpen={isCreateModalOpen} handleCloseModal={handleCloseModal} type={createModalType} />}
        <Container
          style={{ maxWidth: '100vw' }}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            height: '100%',
            width: '100%',
            backgroundColor: 'background.default'
          }}>
          <Typography
            sx={{
              fontFamily: 'Fresca',
              fontSize: '50px',
              fontWeight: '600',
              color: 'text.primary',
              paddingLeft: '10%'
            }}>
            Builds
          </Typography>
          <Container sx={{ display: 'flex', flexDirection: 'row', margin: 0, alignItems: 'center' }}>
            <Typography
              sx={{
                fontFamily: 'Fresca',
                fontSize: '30px',
                fontWeight: '600',
                color: 'text.primary',
                paddingLeft: '10%',
                marginTop: '2%',
                marginBottom: '2%',
                textDecoration: 'underline'
              }}>
              Test Builds
            </Typography>
            <Button sx={{ height: 40 }} onClick={() => handleCreateModalOpen('Test')}>
              <AddBoxIcon />
            </Button>
          </Container>
          {isTestersLoading ? (
            <Loader height="100px" width="100px" />
          ) : (
            <>
              <DataGrid
                columns={testColumns}
                rows={testRows}
                rowHeight={50}
                autoHeight={true}
                sx={{
                  color: ctx.light ? 'text.secondary' : 'text.secondary',
                  fontWeight: 600,
                  borderColor: 'text.primary',
                  marginBottom: 10
                }}
              />
            </>
          )}
          <Container sx={{ display: 'flex', flexDirection: 'row', margin: 0, alignItems: 'center' }}>
            <Typography
              sx={{
                fontFamily: 'Fresca',
                fontSize: '30px',
                fontWeight: '600',
                color: 'text.primary',
                paddingLeft: '10%',
                marginTop: '2%',
                marginBottom: '2%',
                textDecoration: 'underline'
              }}>
              Release Builds
            </Typography>
            <Button sx={{ height: 40 }} onClick={() => handleCreateModalOpen('Release')}>
              <AddBoxIcon />
            </Button>
          </Container>
          {isMajorsLoading ? (
            <Loader height="100px" width="100px" />
          ) : (
            <DataGrid
              columns={majorColumns}
              rows={majorRows}
              rowHeight={50}
              autoHeight={true}
              sx={{
                color: ctx.light ? 'text.secondary' : 'text.secondary',
                fontWeight: 600,
                borderColor: 'text.primary',
                marginBottom: 20
              }}
            />
          )}
          <Container
            sx={{
              display: 'flex',
              width: '100%',
              alignContent: 'end',
              alignItems: 'center',
              justifyContent: 'center',
              paddingBottom: '3vh'
            }}>
            <Typography
              sx={{
                fontFamily: 'Fresca',
                fontSize: '30px',
                fontWeight: '600',
                color: 'text.primary',
                marginRight: '1vw'
              }}>
              Powered by
            </Typography>
            <img src={require('../../assets/nf-logo.png')} alt="BLACKDOG_logo" width="35%" />
          </Container>
        </Container>
      </ThemeProvider>
    </>
  );
};

export default Builds;
