import { IconButton, Typography } from '@mui/material';
import { FunctionComponent } from 'react';

interface MenuButtonProps {
  isActive?: boolean;
  onClick: () => void;
  text: string;
}

const MenuButton: FunctionComponent<MenuButtonProps> = ({ isActive, onClick, text }) => {
  return (
    <>
      <IconButton
        size="small"
        edge="start"
        color="inherit"
        aria-label="dashboard-button"
        sx={{
          mr: 2,
          ml: 2,
          borderWidth: '4px',
          borderStyle: 'solid',
          borderRadius: '5px',
          borderColor: isActive ? '#7e00ed5b' : '#00000000',
          ':hover': { borderColor: '#00eeffaf' }
        }}
        onClick={onClick}>
        <Typography sx={{ color: 'text.secondary' }}>{text}</Typography>
      </IconButton>
    </>
  );
};

export default MenuButton;
