import { Box, Fade } from '@mui/material'
import { FunctionComponent, useEffect, useState } from 'react'

export interface ILoaderProps {
  width: string
  height: string
}

const Loader: FunctionComponent<ILoaderProps> = ({ width, height }) => {
  const [isFading, setIsFading] = useState(true)
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsFading((prev) => !prev)
    }, 500)
    return () => clearTimeout(timer)
  }, [isFading])

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Fade in={isFading} timeout={500}>
          <img
            src={require('../../assets/loader_icon.png')}
            style={{ width, height }}
            alt="nightmare-fuel-icon"
          />
        </Fade>
      </Box>
    </>
  )
}

export default Loader
