import React from 'react';
import { FunctionComponent, useContext, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Admins from '../components/Admins/Admins';
import Builds from '../components/Builds/Builds';
import ButtonAppBar from '../components/UI/ButtonAppBar/ButtonAppBar';
import Testers from '../components/Testers/Testers';
import { AppContext } from '../context/appContext';
import LoginPage from '../pages/LoginPage';
import { roles } from '../shared/enums/roles.enum';
import BugReports from '../components/BugReports/BugReports';
import BugReport from '../components/BugReports/BugReport';
import TesterBuilds from '../components/Builds/TesterBuilds';
import TesterButtonAppBar from '../components/UI/TesterButtonAppBar/TesterButtonAppBar';
import TesterBugReports from '../components/BugReports/TesterBugReports';
import TesterBugReport from '../components/BugReports/TesterBugReport';

interface RouterProps {}

const Router: FunctionComponent<RouterProps> = () => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [activeTab, setActiveTab] = React.useState('builds');
  const [fromStorage, setFromStorage] = React.useState(false);

  useEffect(() => {
    if (appContext.loggedIn) {
      navigate('/builds');
    }
  }, [appContext.loggedIn]);

  useEffect(() => {
    const authHeader = localStorage.getItem('Authorization');
    const expire = localStorage.getItem('auth_expire');
    const id = localStorage.getItem('id');
    const adminLogin = localStorage.getItem('adminLogin');
    if (authHeader && expire && id) {
      const expires = new Date(expire);
      if (expires <= new Date()) {
        localStorage.removeItem('Authorization');
        localStorage.removeItem('auth_expire');
        localStorage.removeItem('id');
        localStorage.removeItem('adminLogin');
        navigate('/login');
      } else {
        setFromStorage(true);
        appContext.LogInFromStorage(authHeader, id, adminLogin === 'true');
      }
    } else {
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    if (location.pathname.split('/')[1] !== activeTab) {
      setActiveTab(location.pathname.split('/')[1]);
    }
  }, [location.pathname]);

  const login_route = <Route path="/login" element={<LoginPage />} key={'login'} />;
  const admin_routes = (
    <>
      <Route path="/admins" element={<Admins />} key="admins" />
      <Route path="/testers" element={<Testers />} key="testers" />
      <Route path="/builds" element={<Builds />} key="build" />
      <Route path="/bug-reports" element={<BugReports />} key="bug-reports" />
      <Route path="/bug-reports/:id" element={<BugReport />} key="bug-report" />
    </>
  );
  const tester_routes = (
    <>
      <Route path="/builds" element={<TesterBuilds />} key="build" />
      <Route path="/bug-reports" element={<TesterBugReports />} key="bug-reports" />
      <Route path="/bug-reports/:id" element={<TesterBugReport />} key="bug-report" />
    </>
  );

  return (
    <>
      {appContext.loggedIn && appContext.user.role === roles.ADMIN && <ButtonAppBar key={'app-bar'} activeTab={activeTab} setActiveTab={setActiveTab} />}
      {appContext.loggedIn && appContext.user.role === roles.TESTER && (
        <>
          <div style={{ display: 'flex', position: 'fixed', zIndex: -1, objectFit: 'fill', height: '100%', width: 'auto' }}>
            <video id="background-video" autoPlay muted>
              <source src={require('../assets/fanatoma_background.webm')} type="video/webm"></source>
            </video>
          </div>
          <TesterButtonAppBar key={'app-bar'} activeTab={activeTab} setActiveTab={setActiveTab} />
        </>
      )}
      <Routes key={'routes'}>{appContext.loggedIn ? [appContext.user.role === roles.ADMIN && admin_routes, appContext.user.role === roles.TESTER && tester_routes] : login_route}</Routes>
    </>
  );
};

export default Router;
