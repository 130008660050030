import React, { FunctionComponent } from 'react';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Button, Typography } from '@mui/material';

interface DownloadBuildButtonProps {}

const DownloadBuildButton: FunctionComponent<DownloadBuildButtonProps> = () => {
  return (
    <div style={{ display: 'flex', alignContent: 'center', justifyItems: 'center' }}>
      <Button
        sx={{
          borderStyle: 'solid',
          borderWidth: 2,
          borderColor: 'white',
          width: '100%',
          cursor: 'pointer',
          backgroundColor: '#c2b20080',
          ':hover': {
            backgroundColor: '#c2b200b8'
          }
        }}>
        <CloudDownloadIcon sx={{ color: 'text.secondary', marginRight: '5px' }} />
        <Typography
          sx={{
            fontFamily: 'Fresca',
            fontStyle: 'normal',
            color: 'text.secondary'
          }}>
          Download
        </Typography>
      </Button>
    </div>
  );
};

export default DownloadBuildButton;
