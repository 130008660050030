import { Container, Typography, Box, ThemeProvider } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../context/appContext';
import { getAllBugReports } from '../../services/ApiService';
import { themeLight, themeDark } from '../../shared/MUI/themes';
import OpenBugReportButton from '../UI/Buttons/OpenBugReportButton';
import Loader from '../UI/Loader';

interface TesterBugReportsProps {}

const TesterBugReports: FunctionComponent<TesterBugReportsProps> = () => {
  const ctx = useContext(AppContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    getAllBugReports(ctx.authHeader, false).then((res) => {
      const rows: any = [];
      res.data.map((report: any) => {
        rows.push({
          id: report.id,
          title: report.title,
          description: report.description,
          status: report.status,
          submitterId: report.submitterId,
          build: report.release,
          severity: report.severity,
          priority: report.priority
        });
      });
      setRows(rows);
      setIsLoading(false);
    });
  }, [ctx.authHeader, isLoading]);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      width: 80,
      editable: false,
      align: 'center'
    },
    {
      field: 'title',
      headerName: 'Bug Title',
      width: 230,
      editable: false
    },
    {
      field: 'description',
      headerName: 'Title Description',
      width: 150,
      editable: false
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 130,
      editable: false
    },
    {
      field: 'build',
      headerName: 'Build',
      width: 130,
      editable: false
    },
    {
      field: 'severity',
      headerName: 'Severity',
      width: 130,
      editable: false
    },
    {
      field: 'priority',
      headerName: 'Priority',
      width: 110,
      editable: false
    },
    {
      field: 'submitterId',
      headerName: 'SubmitterId',
      width: 100,
      editable: false
    },
    {
      field: 'open',
      headerName: 'Expand',
      width: 110,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <div onClick={() => navigate(`/bug-reports/${cellValues.id}`)}>
            <OpenBugReportButton />
          </div>
        );
      }
    }
  ];

  return (
    <>
      <ThemeProvider theme={ctx.light ? themeLight : themeDark}>
        <Container
          style={{ maxWidth: '100vw' }}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            height: '90vh',
            width: '100%',
            backgroundColor: '#00000000'
          }}>
          <Typography
            sx={{
              fontFamily: 'Fresca',
              fontSize: '50px',
              fontWeight: '600',
              color: 'text.primary',
              paddingLeft: '10%',
              marginBottom: '5vh'
            }}>
            Bug Reports
          </Typography>
          <Box
            sx={{
              display: 'flex',
              height: 600,
              width: '90%',
              alignItems: 'center',
              justifyContent: 'center',
              alignSelf: 'center',
              borderStyle: 'solid',
              borderWidth: 3,
              borderColor: 'text.primary',
              borderRadius: 2
            }}>
            {isLoading ? (
              <Loader height="100px" width="100px" />
            ) : (
              <DataGrid
                columns={columns}
                rows={rows}
                sx={{
                  color: ctx.light ? 'text.secondary' : 'text.secondary',
                  fontWeight: 600,
                  borderColor: 'text.primary',
                  backgroundColor: '#000000a0'
                }}
              />
            )}
          </Box>
          <Container
            sx={{
              display: 'flex',
              width: '100%',
              alignContent: 'end',
              alignItems: 'center',
              justifyContent: 'center',
              paddingBottom: '3vh'
            }}>
            <Typography
              sx={{
                fontFamily: 'Fresca',
                fontSize: '30px',
                fontWeight: '600',
                color: 'text.primary',
                marginRight: '1vw'
              }}>
              Powered by
            </Typography>
            <img src={require('../../assets/nf-logo.png')} alt="BLACKDOG_logo" width="35%" />
          </Container>
        </Container>
      </ThemeProvider>
    </>
  );
};

export default TesterBugReports;
