import { Button } from '@mui/material';
import { FunctionComponent, useContext } from 'react';
import BrightnessIcon from '@mui/icons-material/Brightness4';
import { AppContext } from '../../../context/appContext';

interface DarkModeButtonProps {
  width: string;
  height: string;
}

const DarkModeButton: FunctionComponent<DarkModeButtonProps> = () => {
  const ctx = useContext(AppContext);

  return (
    <>
      <Button onClick={ctx.toggleTheme} variant="outlined" sx={{ fontSize: '50px', marginRight: '5%' }}>
        <BrightnessIcon sx={{ color: 'text.primary' }} />
      </Button>
    </>
  );
};
export default DarkModeButton;
