import { Button, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import BugReportIcon from '@mui/icons-material/BugReport';

interface openBugReportButtonProps {}

const openBugReportButton: FunctionComponent<openBugReportButtonProps> = () => {
  return (
    <div>
      <Button
        sx={{
          borderStyle: 'solid',
          borderWidth: 2,
          borderColor: 'white',
          width: '100%',
          cursor: 'pointer',
          backgroundColor: '#59005c99',
          ':hover': {
            backgroundColor: '#76007a99'
          }
        }}>
        <BugReportIcon sx={{ color: 'text.secondary' }} />
        <Typography
          sx={{
            fontFamily: 'Fresca',
            fontStyle: 'normal',
            color: 'text.secondary'
          }}>
          Expand
        </Typography>
      </Button>
    </div>
  );
};

export default openBugReportButton;
