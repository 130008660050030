import React, { FunctionComponent } from 'react'
import LockIcon from '@mui/icons-material/Lock'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import { Button, Typography } from '@mui/material'

interface UserActivationButtonProps {
  userIsActive: boolean
}

const UserActivationButton: FunctionComponent<UserActivationButtonProps> = ({
  userIsActive,
}) => {
  return (
    <div>
      {userIsActive ? (
        <Button
          sx={{
            borderStyle: 'solid',
            borderWidth: 2,
            borderColor: 'white',
            width: '100%',
            backgroundColor: 'rgba(160,0,0,0.6)',
            ':hover': {
              backgroundColor: 'rgba(160,0,0,0.93)',
            },
          }}
        >
          <LockIcon sx={{ color: 'text.secondary' }} />
          <Typography
            sx={{
              fontFamily: 'Fresca',
              fontStyle: 'normal',
              color: 'text.secondary',
            }}
          >
            Deactivate
          </Typography>
        </Button>
      ) : (
        <Button
          sx={{
            borderStyle: 'solid',
            borderWidth: 2,
            borderColor: 'white',
            width: '100%',
            backgroundColor: 'rgba(55,180,48,0.60)',
            ':hover': {
              backgroundColor: 'rgba(55,180,48,0.93)',
            },
          }}
        >
          <LockOpenIcon sx={{ color: 'text.secondary' }} />
          <Typography
            sx={{
              fontFamily: 'Fresca',
              fontStyle: 'normal',
              color: 'text.secondary',
            }}
          >
            Activate
          </Typography>
        </Button>
      )}
    </div>
  )
}

export default UserActivationButton
