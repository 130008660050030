import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, Container, IconButton, InputAdornment, Paper, TextField, ThemeProvider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ChangeEvent, FunctionComponent, useContext, useEffect, useState } from 'react';
import { AppContext } from '../context/appContext';
import { colors } from '../shared/MUI/colors';
import { themeDark, themeLight } from '../shared/MUI/themes';
import '../assets/background.webm';

interface LoginPageProps {}

const LoginPage: FunctionComponent<LoginPageProps> = () => {
  const ctx = useContext(AppContext);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [displayName, setDisplayName] = useState('');
  const [displayNameError, setDisplayNameError] = useState(false);
  const [password, setPassword] = useState('');
  const [passError, setPassError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoggingIn, setIsLogginIn] = useState(false);
  const [adminLogin, setAdminLogin] = useState(false);
  const [registrationSuccessful, setRegistrationSuccessful] = useState(false);

  const [isRegister, setIsRegister] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (emailError && validateEmail(e.target.value)) {
      setEmailError(false);
    }
    setEmail(e.target.value);
  };

  const handlePassChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPassword(e.target.value);
    if (password !== '') setPassError(false);
  };

  const handleDisplayNameChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setDisplayName(e.target.value);
    if (displayName !== '') setDisplayNameError(false);
    else setDisplayNameError(true);
  };

  const handleLogin = async () => {
    setIsLogginIn(true);
    if (validateEmail(email)) {
      if (adminLogin) {
        ctx.logInAdmin(email, password);
      } else {
        ctx.logInTester(email, password);
      }
    } else {
      setEmailError(true);
      setIsLogginIn(false);
    }
  };

  const handleCreateTester = async () => {
    setIsLogginIn(true);
    if (validateEmail(email)) {
      ctx.registerTester(email, password, displayName).then((res: any) => {
        if (res.status && res.status === 201) {
          ctx.user.error = false;
          setIsRegister(false);
          setRegistrationSuccessful(true);
        }
        setIsLogginIn(false);
      });
    } else {
      setEmailError(true);
      setIsLogginIn(false);
    }
  };

  const handleMainClick = () => {
    if (isRegister) {
      handleCreateTester();
    } else {
      handleLogin();
    }
  };

  const handleModeSwitch = () => {
    setIsRegister((current) => !current);
  };

  const validateEmail = (email: string) => {
    if (email === 'nightmare') return email;
    return String(email)
      .toLowerCase()
      .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  };

  useEffect(() => {
    if (ctx.user.error) {
      setEmailError(true);
      setPassError(true);
      setIsLogginIn(false);
    }
  }, [ctx]);

  useEffect(() => {
    ctx.user.error = false;
    setEmailError(false);
    setPassError(false);
  }, [isRegister]);

  return (
    <ThemeProvider theme={ctx.light ? themeLight : themeDark}>
      <div style={{ display: 'flex', position: 'absolute', zIndex: -1, width: '100vw', height: '100vh', left: 0, right: 0, marginLeft: 'auto', marginRight: 'auto', backgroundColor: 'black' }}>
        <video id="background-video" autoPlay muted style={{ display: 'flex', position: 'absolute', zIndex: -1, height: '100vh', left: 0, right: 0, marginLeft: 'auto', marginRight: 'auto' }}>
          <source src={require('../assets/background.webm')} type="video/webm"></source>
        </video>
      </div>
      <Container
        maxWidth={false}
        sx={{
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
          width: '100vw',
          height: '100vh',
          justifyContent: 'space-evenly',
          maxWidth: '100vw'
        }}>
        <Container
          sx={{
            display: 'flex',
            width: '100%',
            alignContent: 'center',
            justifyContent: 'center',
            paddingTop: '1vh'
          }}>
          <img src={require('../assets/bd-logo.png')} alt="BLACKDOG_logo" width={isRegister ? '30%' : '50%'} />
        </Container>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap'
          }}>
          <Paper
            elevation={20}
            sx={{
              backgroundColor: 'secondary.main',
              justifyContent: 'center',
              textAlign: 'center',
              width: '480px',
              minWidth: '400px',
              height: isRegister ? '500px' : '400px',
              borderRadius: '8px'
            }}>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={() => setAdminLogin((current) => !current)} variant="outlined" sx={{ position: 'absolute', height: 40, width: 120, marginRight: '-65px', marginTop: '-12px', backgroundColor: colors.raspberry_bg_login }}>
                <Typography
                  sx={{
                    fontFamily: 'Fresca',
                    fontSize: '15px',
                    fontWeight: '600',
                    color: 'text.primary'
                  }}>
                  Switch role
                </Typography>
              </Button>
            </div>
            <Typography
              sx={{
                fontFamily: 'Fresca',
                fontSize: '50px',
                fontWeight: '600',
                color: 'text.primary'
              }}>
              {adminLogin ? 'Admin Hub' : 'Testing Hub'}
            </Typography>
            {ctx.user.error && (
              <Container
                sx={{
                  display: 'flex',
                  height: '30px',
                  justifyContent: 'center',
                  marginBottom: '2vh'
                }}>
                <Typography
                  sx={{
                    fontFamily: 'Fresca',
                    fontSize: '18px',
                    marginTop: '20px',
                    color: 'red'
                  }}>
                  {ctx.user.errorMessage}
                </Typography>
              </Container>
            )}
            {registrationSuccessful && !ctx.user.error && !emailError && (
              <Container
                sx={{
                  display: 'flex',
                  height: '30px',
                  justifyContent: 'center',
                  marginBottom: '6vh'
                }}>
                <Typography
                  sx={{
                    fontFamily: 'Fresca',
                    fontSize: '18px',
                    marginTop: '20px',
                    color: 'green'
                  }}>
                  {'Registration successful! Your account will be activated soon!'}
                </Typography>
              </Container>
            )}
            <Container
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}>
              <TextField
                id="username"
                value={email}
                disabled={isLoggingIn}
                error={emailError}
                onChange={(event) => {
                  handleEmailChange(event);
                }}
                label="Username"
                variant="outlined"
                sx={{
                  marginBottom: '10px',
                  marginTop: '10px',
                  color: 'text.primary',
                  input: { color: 'info.main' }
                }}
              />
              {isRegister && (
                <TextField
                  id="displayName"
                  value={displayName}
                  disabled={isLoggingIn}
                  error={emailError}
                  onChange={(event) => {
                    handleDisplayNameChange(event);
                  }}
                  label="Display Name"
                  variant="outlined"
                  sx={{
                    marginBottom: '20px',
                    marginTop: '10px',
                    color: 'text.primary',
                    input: { color: 'info.main' }
                  }}
                />
              )}
              <TextField
                id="password"
                value={password}
                disabled={isLoggingIn}
                error={passError}
                onChange={handlePassChange}
                label="Password"
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                sx={{ input: { color: 'info.main' } }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ m: 0 }}>
                      <IconButton sx={{ m: 0 }} aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} color="primary">
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Container>
            <Container
              sx={{
                display: 'flex',
                flexDirection: 'row',
                height: '30px',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                marginTop: '100px',
                paddingBottom: '25px'
              }}>
              <Button
                onClick={handleMainClick}
                disabled={isLoggingIn}
                variant="outlined"
                sx={{
                  height: '80px',
                  width: '180px',
                  backgroundColor: colors.raspberry_bg_login
                }}>
                <Typography
                  sx={{
                    fontFamily: 'Fresca',
                    fontWeight: '600',
                    fontSize: '30px',
                    color: colors.cyan
                  }}>
                  {isRegister ? 'Register' : 'LOGIN'}
                </Typography>
              </Button>
              <Button disabled={adminLogin} variant="outlined" onClick={handleModeSwitch} sx={{ height: '50px' }}>
                <Typography
                  sx={{
                    fontFamily: 'Fresca',
                    fontWeight: '600',
                    fontSize: '20px'
                  }}>
                  {isRegister ? 'Cancel' : 'Register'}
                </Typography>
              </Button>
            </Container>
          </Paper>
        </Box>
        <Container
          sx={{
            display: 'flex',
            width: '100%',
            alignContent: 'end',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: '3vh'
          }}>
          <Typography
            sx={{
              fontFamily: 'Fresca',
              fontSize: '30px',
              fontWeight: '600',
              color: 'text.primary',
              marginRight: '1vw'
            }}>
            Powered by
          </Typography>
          <img src={require('../assets/nf-logo.png')} alt="BLACKDOG_logo" width="35%" />
        </Container>
      </Container>
    </ThemeProvider>
  );
};

export default LoginPage;
