import { ChangeEvent, FunctionComponent, useContext, useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { Button, Container, Dialog, Modal, Paper, TextField, Typography } from '@mui/material';
import { colors } from '../../shared/MUI/colors';
import { AppContext } from '../../context/appContext';
import { createMajorRelease, createTestRelease } from '../../services/ApiService';

interface NewBuildModalProps {
  isModalOpen: boolean;
  handleCloseModal: () => void;
  type: 'Release' | 'Test' | '';
}

const NewBuildModal: FunctionComponent<NewBuildModalProps> = ({ isModalOpen, handleCloseModal, type }) => {
  const ctx = useContext(AppContext);
  const [name, setName] = useState('');
  const [link, setLink] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleNameChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setName(e.target.value);
  };

  const handleLinkChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLink(e.target.value);
  };

  const handleCreate = () => {
    setError(false);
    if (type === 'Test') {
      createTestRelease({ name: name, link: link, uploaderId: ctx.user.id.toString() }, ctx.authHeader).then((res) => {
        if (res.status === 201) {
          handleCloseModal();
        } else {
          setError(true);
          setErrorMessage(res.statusText);
        }
      });
    }
    if (type === 'Release') {
      const numbers = name.split('.');
      const version: number[] = [];
      numbers.map((num) => version.push(parseInt(num)));
      try {
        createMajorRelease({ version: version, link: link, uploaderId: ctx.user.id.toString() }, ctx.authHeader).then(() => {
          handleCloseModal();
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <Modal
      open={isModalOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%'
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '80%',
          width: '60%',
          justifyContent: 'top',
          alignItems: 'center',
          backgroundColor: 'rgba(0,0,0,0.85)',
          paddingTop: 4,
          borderStyle: 'solid',
          borderWidth: 1,
          borderRadius: 3
        }}>
        <Typography
          id="modal-modal-title"
          sx={{
            fontFamily: 'Fresca',
            fontSize: '48px',
            fontWeight: '600',
            color: 'text.primary'
          }}>
          Create {type} Build
        </Typography>
        {error && (
          <Typography
            sx={{
              fontFamily: 'Fresca',
              fontWeight: '600',
              fontSize: '20px',
              marginRight: 3,
              width: '20%',
              color: 'red'
            }}>
            {errorMessage}
          </Typography>
        )}
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            maxWidth: '100%'
          }}>
          <Paper
            elevation={20}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#00000000',
              justifyContent: 'center',
              textAlign: 'center',
              width: '700px',
              minWidth: '400px',
              height: '55vh',
              borderRadius: '8px'
            }}>
            <Container
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '30px',
                width: '100%',
                justifyContent: 'center',
                marginTop: '80px'
              }}>
              <Container
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '30px',
                  width: '100%',
                  justifyContent: 'center'
                }}></Container>
              <Box
                sx={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'row',
                  height: 80,
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <Typography
                  sx={{
                    fontFamily: 'Fresca',
                    fontWeight: '600',
                    fontSize: '20px',
                    marginRight: 3,
                    width: '20%'
                  }}>
                  Build Name
                </Typography>
                <TextField
                  id="buildname"
                  value={name}
                  onChange={handleNameChange}
                  variant="outlined"
                  sx={{
                    width: '80%',
                    marginBottom: '30px',
                    marginTop: '30px',
                    color: 'info.main',
                    borderStyle: 'solid',
                    borderWidth: 1,
                    borderColor: 'text.primary',
                    borderRadius: 1,
                    input: { color: colors.cyan }
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'row',
                  height: 80,
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <Typography
                  sx={{
                    fontFamily: 'Fresca',
                    fontWeight: '600',
                    fontSize: '20px',
                    marginRight: 3,
                    width: '20%'
                  }}>
                  Download Link
                </Typography>
                <TextField
                  id="link"
                  variant="outlined"
                  value={link}
                  onChange={handleLinkChange}
                  sx={{
                    width: '80%',
                    marginBottom: '30px',
                    marginTop: '30px',
                    color: 'info.main',
                    borderStyle: 'solid',
                    borderWidth: 1,
                    borderColor: 'text.primary',
                    borderRadius: 1,
                    input: { color: colors.cyan }
                  }}
                />
              </Box>
            </Container>
            <Container
              sx={{
                display: 'flex',
                flexDirection: 'row',
                height: '30px',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                marginTop: '150px',
                paddingBottom: '25px'
              }}>
              <Button
                variant="outlined"
                onClick={handleCreate}
                sx={{
                  height: '80px',
                  width: '180px',
                  backgroundColor: colors.violet_dark
                }}>
                <Typography
                  sx={{
                    fontFamily: 'Fresca',
                    fontWeight: '600',
                    fontSize: '30px',
                    color: colors.cyan
                  }}>
                  Create
                </Typography>
              </Button>
              <Button variant="outlined" onClick={handleCloseModal}>
                <Typography
                  sx={{
                    fontFamily: 'Fresca',
                    fontWeight: '600',
                    fontSize: '20px',
                    color: ctx.light ? colors.cyan : 'text.secondary'
                  }}>
                  Cancel
                </Typography>
              </Button>
            </Container>
          </Paper>
        </Box>
      </Box>
    </Modal>
  );
};

export default NewBuildModal;
