export enum colors {
  cyan = '#0CADE8ff',
  fluorescent = '#00E8E9ff',
  violet = '#6870C5ff',
  violet_dark = '#6900cc',
  violet_bg = '#220042c7',
  raspberry = '#E21B55ff',
  raspberry_bg = '#E21B5518',
  raspberry_bg_light = '#E21B5590',
  raspberry_bg_login = '#E21B5561',
  cambridge = '#9AB9AAff',
  tomato = '#F0694Aff',
  green = '#009427'
}
