import React, { FunctionComponent } from 'react';
import { createTester, getAdmin, getTester, loginAdmin, loginUser } from '../services/ApiService';
import { roles } from '../shared/enums/roles.enum';

export const AppContext = React.createContext({
  loggedIn: false,
  light: false,
  user: {
    id: 0,
    email: '',
    display_name: '',
    role: roles.NULL,
    error: false,
    errorMessage: ''
  },
  authHeader: {},
  toggleTheme: () => {},
  logInTester: (email: string, password: string) => {},
  logInAdmin: (email: string, password: string) => {},
  LogInFromStorage: (authHeader: string, id: string, adminLogin: boolean) => {},
  logOut: () => {},
  registerTester: async (email: string, password: string, display_name: string) => {}
});

interface AppContextProviderProps {
  children: JSX.Element;
}

const AppContextProvider: FunctionComponent<AppContextProviderProps> = ({ children }) => {
  const [light, setLight] = React.useState(false);
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [user, setUser] = React.useState({
    id: 0,
    email: '',
    display_name: '',
    role: roles.ADMIN,
    error: false,
    errorMessage: ''
  });
  const [authHeader, setAuthHeader] = React.useState({});

  const toggleTheme = () => {
    setLight((prev) => !prev);
  };

  const finalizeLoginUser = (user_data: any, authHeader: string, adminLogin: boolean) => {
    const role = adminLogin ? roles.ADMIN : roles.TESTER;
    setUser({ ...user_data, role });
    !user_data.enabled &&
      setUser((prev) => {
        return { ...prev, error: true, errorMessage: 'Error: The account is not Active...' };
      });
    setLoggedIn(user_data.enabled);
    if (user_data.enabled) {
      makeAuthHeader(user_data, authHeader, adminLogin);
    }
  };

  const logInTester = async (email: string, password: string) => {
    try {
      loginUser(email, password).then((res: any) => {
        res.data
          ? finalizeLoginUser(res.data.user, res.data.access_token, false)
          : setUser((current) => {
              return { ...current, error: true, errorMessage: res.response.data.message };
            });
      });
    } catch (error) {
      setUser((current) => {
        return { ...current, error: true };
      });
    }
  };

  const logInAdmin = async (email: string, password: string) => {
    try {
      loginAdmin(email, password).then((res: any) => {
        res.data
          ? finalizeLoginUser(res.data.user, res.data.access_token, true)
          : setUser((current) => {
              return { ...current, error: true, errorMessage: res.response.data.message };
            });
      });
    } catch (error) {
      setUser((current) => {
        return { ...current, error: true };
      });
    }
  };

  const LogInFromStorage = (authHeader: string, id: string, adminLogin: boolean) => {
    if (adminLogin) {
      try {
        return getAdmin(id, { Authorization: `Bearer ${authHeader}` }).then((res: any) => {
          res.data
            ? finalizeLoginUser(res.data, authHeader, true)
            : setUser((current) => {
                return { ...current, error: true, errorMessage: res.response.data.message };
              });
        });
      } catch (error) {
        return error;
      }
    } else {
      try {
        return getTester(id, { Authorization: `Bearer ${authHeader}` }).then((res: any) => {
          res.data
            ? finalizeLoginUser(res.data, authHeader, false)
            : setUser((current) => {
                return { ...current, error: true, errorMessage: res.response.data.message };
              });
        });
      } catch (error) {
        return error;
      }
    }
  };

  const logOut = () => {
    localStorage.removeItem('Authorization');
    localStorage.removeItem('auth_expire');
    setUser(initialValues.user);
    setLoggedIn(false);
    setAuthHeader('');
  };

  const registerTester = async (email: string, password: string, display_name: string) => {
    try {
      return await createTester(email, password, display_name);
    } catch (error: any) {
      setUser((current) => {
        return { ...current, error: true, errorMessage: error.response.data.message };
      });
      console.log(error);
    }
  };

  const makeAuthHeader = (user_data: any, access_token: string, adminLogin: boolean) => {
    console.log(access_token);
    if (user_data.id && access_token) {
      let bearerToken = 'Bearer ' + access_token;
      setAuthHeader({ Authorization: bearerToken });
      localStorage.setItem('Authorization', access_token);
      localStorage.setItem('id', user_data.id);
      localStorage.setItem('adminLogin', adminLogin.toString());
      let date = new Date();
      let expiryDate = new Date(date.setMonth(date.getMonth() + 1));
      localStorage.setItem('auth_expire', expiryDate.toString());
    }
  };

  const initialValues = {
    loggedIn,
    logInAdmin,
    logInTester,
    logOut,
    user: user,
    light,
    toggleTheme,
    registerTester,
    authHeader,
    LogInFromStorage
  };

  return (
    <>
      <AppContext.Provider value={initialValues}>{children}</AppContext.Provider>
    </>
  );
};

export default AppContextProvider;
