import { createTheme } from '@mui/material'
import { colors } from './colors'

export const themeLight = createTheme({
  palette: {
    background: {
      default: '#eaeaea',
    },
    secondary: {
      main: colors.violet_bg,
      dark: colors.raspberry_bg_light,
    },
    text: {
      primary: colors.cyan,
      secondary: '#000000'
    },
    info: {
      main: '#000000'
    },
  },
  typography: {
    fontFamily: ['Raleway', 'Fresca'].join(','),
  },
})

export const themeDark = createTheme({
  palette: {
    background: {
      default: '#222222',
    },
    secondary: {
      main: colors.violet_bg,
      dark: colors.raspberry
    },
    text: {
      primary: colors.cyan,
      secondary: colors.cambridge
    },
  },
  typography: {
    fontFamily: ['Raleway', 'Fresca'].join(','),
  },
})
