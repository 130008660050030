import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DarkModeButton from '../Buttons/DarkModeButton';
import MenuButton from '../Buttons/MenuButton';
import { Container, ThemeProvider } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { useContext } from 'react';
import { AppContext } from '../../../context/appContext';
import NewPasswordModal from '../NewPasswordModal';
import { useNavigate } from 'react-router-dom';
import { themeLight, themeDark } from '../../../shared/MUI/themes';

interface TesterButtonAppBarProps {
  activeTab: string;
  setActiveTab: (newTab: string) => void;
}

const TesterButtonAppBar: React.FunctionComponent<TesterButtonAppBarProps> = ({ activeTab, setActiveTab }) => {
  const ctx = useContext(AppContext);
  const navigate = useNavigate();
  const [isSettingsShown, setIsSettingsShown] = React.useState(false);
  const [isNewPasswordModalShown, setIsNewPasswordModalShown] = React.useState(false);

  const toggleSettings = () => {
    setIsSettingsShown((current) => !current);
  };

  const closePasswordModal = () => {
    setIsNewPasswordModalShown(false);
    setIsSettingsShown(false);
  };

  const openPasswordModal = () => {
    setIsNewPasswordModalShown(true);
  };

  const handleTest = () => {
    setActiveTab('bug-reports');
    navigate('/bug-reports');
  };

  return (
    <>
      <ThemeProvider theme={ctx.light ? themeLight : themeDark}>
        <NewPasswordModal isModalOpen={isNewPasswordModalShown} onCancel={closePasswordModal} />
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static" sx={{ backgroundColor: '#00000080' }}>
            <Toolbar>
              <IconButton onClick={handleTest} size="large" edge="start" color="inherit" aria-label="dashboard-button" sx={{ mr: 2 }}>
                <img src={require('../../../assets/icon.png')} style={{ width: '50px', height: '45px' }} />
              </IconButton>
              <MenuButton
                isActive={activeTab === 'builds'}
                key={'builds'}
                onClick={() => {
                  setActiveTab('builds');
                  navigate('/builds');
                }}
                text={'Builds'}
              />
              <MenuButton
                isActive={activeTab === 'bug-reports'}
                key={'bug-reports'}
                onClick={() => {
                  setActiveTab('bug-reports');
                  navigate('/bug-reports');
                }}
                text={'Bug Reports'}
              />
              <Container
                sx={{
                  display: 'flex',
                  height: '30px',
                  justifyContent: 'right'
                }}>
                <IconButton onClick={toggleSettings} sx={{ p: 0, borderStyle: 'solid', borderWidth: 1, borderRadius: 100, color: 'text.primary', width: 30, height: 30 }}>
                  <PersonIcon />
                </IconButton>
                {isSettingsShown && (
                  <Box sx={{ position: 'absolute', display: 'flex', flexDirection: 'column', alignContent: 'center', justifyItems: 'center', padding: 2, top: '10vh', left: '85vw', borderStyle: 'solid', borderWidth: 1, borderRadius: 5, color: 'text.primary', backgroundColor: '#ffffff10' }}>
                    <div style={{ borderStyle: 'solid', borderWidth: 0, borderBottomWidth: 1 }}>
                      <IconButton
                        size="small"
                        edge="start"
                        color="inherit"
                        aria-label="dashboard-button"
                        sx={{
                          mr: 2,
                          ml: 2,
                          borderWidth: '4px',
                          borderStyle: 'solid',
                          borderRadius: '5px',
                          borderColor: '#00000000',
                          width: 150,
                          ':hover': { borderColor: '#00eeffaf' }
                        }}
                        onClick={openPasswordModal}>
                        <Typography sx={{ color: 'text.secondary' }}>{'New Password'}</Typography>
                      </IconButton>
                    </div>
                    <MenuButton
                      key={'logout'}
                      onClick={() => {
                        ctx.logOut();
                        navigate('/login');
                      }}
                      text={'Logout'}
                    />
                  </Box>
                )}
              </Container>
            </Toolbar>
          </AppBar>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default TesterButtonAppBar;
