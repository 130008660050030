import { Box, Button, Container, InputLabel, MenuItem, Select, SelectChangeEvent, ThemeProvider, Typography } from '@mui/material';
import { FunctionComponent, SetStateAction, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../context/appContext';
import { getBugReportById, updateBugReportStatus } from '../../services/ApiService';
import { BugPriority } from '../../shared/enums/bug-priority.enum';
import { BugSeverity } from '../../shared/enums/bug-severity.enum';
import { BugReportStatus } from '../../shared/enums/bug-status.enum';
import { roles } from '../../shared/enums/roles.enum';
import { colors } from '../../shared/MUI/colors';
import { themeLight, themeDark } from '../../shared/MUI/themes';
import Loader from '../UI/Loader';

interface TesterBugReportProps {}

interface Report {
  id: number;
  title: string;
  description: string;
  status: string;
  submitterId: number;
  submitterEmail: string;
  submitterName: string;
  release: string;
  priority: BugPriority;
  severity: BugSeverity;
}

const TesterBugReport: FunctionComponent = () => {
  const ctx = useContext(AppContext);
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [report, setReport] = useState<Report>();

  const [selectedStatus, setSelectedStatus] = useState('SUBMITTED');

  useEffect(() => {
    params.id &&
      getBugReportById(parseInt(params.id), ctx.authHeader, false).then((res) => {
        setReport(res.data);
        setSelectedStatus(res.data.status);
        setIsLoading(false);
      });
  }, [ctx.authHeader, isLoading, params.id]);

  return (
    <>
      <ThemeProvider theme={ctx.light ? themeLight : themeDark}>
        <Container
          style={{ maxWidth: '100vw' }}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            height: '90vh',
            width: '100%',
            backgroundColor: '#000000a0'
          }}>
          {isLoading ? (
            <Loader height="100px" width="100px" />
          ) : (
            <>
              <Container sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Ubuntu',
                      fontSize: '35px',
                      fontWeight: '600',
                      color: 'text.primary',
                      marginBottom: '5vh'
                    }}>
                    {report && report.title}
                  </Typography>
                </div>
              </Container>
              <Container sx={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Ubuntu',
                      fontSize: '20px',
                      fontWeight: '600',
                      color: 'text.secondary',
                      marginBottom: '5vh',
                      marginRight: '1vw'
                    }}>
                    {`Tester ID: `}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Ubuntu',
                      fontSize: '20px',
                      fontWeight: '600',
                      color: 'text.primary',
                      marginBottom: '5vh',
                      marginRight: '5vw'
                    }}>
                    {`${report?.id}`}
                  </Typography>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Ubuntu',
                      fontSize: '20px',
                      fontWeight: '600',
                      color: 'text.secondary',
                      marginBottom: '5vh',
                      marginRight: '1vw'
                    }}>
                    {`Tester e-mail: `}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Ubuntu',
                      fontSize: '20px',
                      fontWeight: '600',
                      color: 'text.primary',
                      marginBottom: '5vh',
                      marginRight: '15vw'
                    }}>
                    {`${report?.submitterEmail}`}
                  </Typography>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Ubuntu',
                      fontSize: '20px',
                      fontWeight: '600',
                      color: 'text.secondary',
                      marginBottom: '5vh',
                      marginRight: '1vw'
                    }}>
                    {`Tester Name: `}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Ubuntu',
                      fontSize: '20px',
                      fontWeight: '600',
                      color: 'text.primary',
                      marginBottom: '5vh',
                      marginRight: '15vw'
                    }}>
                    {`${report?.submitterName}`}
                  </Typography>
                </div>
              </Container>
              <div style={{ display: 'flex', width: '93%', alignSelf: 'center', alignContent: 'center', flexDirection: 'row', marginLeft: '10vw' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Ubuntu',
                      fontSize: '20px',
                      fontWeight: '600',
                      color: 'text.secondary',
                      marginRight: '1vw'
                    }}>
                    {`BUILD: `}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Ubuntu',
                      fontSize: '20px',
                      fontWeight: '600',
                      color: 'text.primary',
                      marginRight: '5vw'
                    }}>
                    {`${report?.release}`}
                  </Typography>
                </div>
                <Typography sx={{ fontFamily: 'Ubuntu', fontSize: '20px', fontWeight: '600', color: 'text.secondary', marginBottom: '5vh', marginRight: '1vw' }}>{'Status:  '}</Typography>
                <Typography
                  sx={{
                    fontFamily: 'Ubuntu',
                    fontSize: '20px',
                    fontWeight: '600',
                    color: 'text.primary',
                    marginBottom: '5vh',
                    marginRight: '5vw'
                  }}>
                  {report && ` ${report.status}`}
                </Typography>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Ubuntu',
                      fontSize: '20px',
                      fontWeight: '600',
                      color: 'text.secondary',
                      marginRight: '1vw'
                    }}>
                    {`Severity: `}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Ubuntu',
                      fontSize: '20px',
                      fontWeight: '600',
                      color: 'text.primary',
                      marginRight: '5vw'
                    }}>
                    {`${report?.severity}`}
                  </Typography>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Ubuntu',
                      fontSize: '20px',
                      fontWeight: '600',
                      color: 'text.secondary',
                      marginRight: '1vw'
                    }}>
                    {`Priority: `}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Ubuntu',
                      fontSize: '20px',
                      fontWeight: '600',
                      color: 'text.primary',
                      marginRight: '5vw'
                    }}>
                    {`${report?.priority}`}
                  </Typography>
                </div>
              </div>
              <Box
                sx={{
                  display: 'flex',
                  height: 600,
                  width: '80%',
                  padding: '3%',
                  alignSelf: 'center',
                  borderStyle: 'solid',
                  borderWidth: 3,
                  borderColor: 'text.secondary',
                  borderRadius: 2
                }}>
                {isLoading ? <Loader height="100px" width="100px" /> : <></>}
                {report && <Typography sx={{ color: 'text.primary' }}>{report.description}</Typography>}
              </Box>
              <Container
                sx={{
                  display: 'flex',
                  width: '100%',
                  alignContent: 'end',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingBottom: '3vh'
                }}>
                <Typography
                  sx={{
                    fontFamily: 'Fresca',
                    fontSize: '30px',
                    fontWeight: '600',
                    color: 'text.primary',
                    marginRight: '1vw'
                  }}>
                  Powered by
                </Typography>
                <img src={require('../../assets/nf-logo.png')} alt="BLACKDOG_logo" width="35%" />
              </Container>
            </>
          )}
        </Container>
      </ThemeProvider>
    </>
  );
};

export default TesterBugReport;
