import { Modal, Box, Typography, Paper, Container, TextField, Button, ThemeProvider } from '@mui/material';
import { ChangeEvent, FunctionComponent, useContext, useState } from 'react';
import { AppContext } from '../../context/appContext';
import { createAdmin } from '../../services/ApiService';
import { colors } from '../../shared/MUI/colors';
import { themeLight, themeDark } from '../../shared/MUI/themes';

interface NewAdminModalProps {
  isModalOpen: boolean;
  handleCloseModal: () => void;
}

const NewAdminModal: FunctionComponent<NewAdminModalProps> = ({ isModalOpen, handleCloseModal }) => {
  const ctx = useContext(AppContext);
  const [email, setEmail] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEmail(e.target.value);
  };

  const handleDisplayNameChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setDisplayName(e.target.value);
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPassword(e.target.value);
  };

  const handleCreate = () => {
    createAdmin(email, displayName, password, ctx.authHeader).then((res) => {
      if (res.status === 201) {
        handleCloseModal();
      }
    });
    setError(false);
    try {
    } catch (e) {
      setError(true);
      setErrorMessage('Error:');
      console.log(e);
    }
  };

  return (
    <ThemeProvider theme={ctx.light ? themeLight : themeDark}>
      <Modal
        open={isModalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%'
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '80%',
            width: '60%',
            justifyContent: 'top',
            alignItems: 'center',
            backgroundColor: 'rgba(0,0,0,0.85)',
            paddingTop: 4,
            borderStyle: 'solid',
            borderWidth: 1,
            borderRadius: 3
          }}>
          <Typography
            id="modal-modal-title"
            sx={{
              fontFamily: 'Fresca',
              fontSize: '48px',
              fontWeight: '600',
              color: colors.cyan
            }}>
            {'Create Admin'}
          </Typography>
          {error && (
            <Typography
              sx={{
                fontFamily: 'Fresca',
                fontWeight: '600',
                fontSize: '20px',
                marginRight: 3,
                width: '20%',
                color: 'red'
              }}>
              {errorMessage}
            </Typography>
          )}
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap',
              maxWidth: '100%'
            }}>
            <Paper
              elevation={20}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#00000000',
                justifyContent: 'center',
                textAlign: 'center',
                width: '700px',
                minWidth: '400px',
                height: '55vh',
                borderRadius: '8px'
              }}>
              <Container
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '30px',
                  width: '100%',
                  justifyContent: 'center',
                  marginTop: '80px'
                }}>
                <Container
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    height: '30px',
                    width: '100%',
                    justifyContent: 'center'
                  }}></Container>
                <Box
                  sx={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'row',
                    height: 80,
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                  <Typography
                    sx={{
                      fontFamily: 'Fresca',
                      fontWeight: '600',
                      fontSize: '20px',
                      marginRight: 3,
                      width: '20%'
                    }}>
                    Email
                  </Typography>
                  <TextField
                    id="email"
                    value={email}
                    onChange={handleEmailChange}
                    variant="outlined"
                    sx={{
                      width: '80%',
                      marginBottom: '30px',
                      marginTop: '30px',
                      color: 'info.main',
                      borderStyle: 'solid',
                      borderWidth: 1,
                      borderColor: colors.cyan,
                      borderRadius: 1,
                      input: { color: colors.cyan }
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'row',
                    height: 80,
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                  <Typography
                    sx={{
                      fontFamily: 'Fresca',
                      fontWeight: '600',
                      fontSize: '20px',
                      marginRight: 3,
                      width: '20%'
                    }}>
                    Display Name
                  </Typography>
                  <TextField
                    id="displayName"
                    variant="outlined"
                    value={displayName}
                    onChange={handleDisplayNameChange}
                    sx={{
                      width: '80%',
                      marginBottom: '30px',
                      marginTop: '30px',
                      color: colors.cyan,
                      borderStyle: 'solid',
                      borderWidth: 1,
                      borderColor: colors.cyan,
                      borderRadius: 1,
                      input: { color: colors.cyan }
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'row',
                    height: 80,
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                  <Typography
                    sx={{
                      fontFamily: 'Fresca',
                      fontWeight: '600',
                      fontSize: '20px',
                      marginRight: 3,
                      width: '20%'
                    }}>
                    Password
                  </Typography>
                  <TextField
                    id="password"
                    value={password}
                    onChange={handlePasswordChange}
                    variant="outlined"
                    sx={{
                      width: '80%',
                      marginBottom: '30px',
                      marginTop: '30px',
                      color: colors.cyan,
                      borderStyle: 'solid',
                      borderWidth: 1,
                      borderColor: colors.cyan,
                      borderRadius: 1,
                      input: { color: colors.cyan }
                    }}
                  />
                </Box>
              </Container>
              <Container
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '30px',
                  alignItems: 'center',
                  justifyContent: 'space-evenly',
                  marginTop: '150px',
                  paddingBottom: '25px'
                }}>
                <Button
                  variant="outlined"
                  onClick={handleCreate}
                  sx={{
                    height: '80px',
                    width: '180px',
                    backgroundColor: colors.violet_dark
                  }}>
                  <Typography
                    sx={{
                      fontFamily: 'Fresca',
                      fontWeight: '600',
                      fontSize: '30px',
                      color: colors.cyan
                    }}>
                    Create
                  </Typography>
                </Button>
                <Button variant="outlined" onClick={handleCloseModal}>
                  <Typography
                    sx={{
                      fontFamily: 'Fresca',
                      fontWeight: '600',
                      fontSize: '20px',
                      color: ctx.light ? colors.cyan : 'text.secondary'
                    }}>
                    Cancel
                  </Typography>
                </Button>
              </Container>
            </Paper>
          </Box>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default NewAdminModal;
