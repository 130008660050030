import axios from 'axios';

const API_URL = 'https://api.nightmarefuelgames.com/api';

export const getPing = () => {
  return axios.get(`${API_URL}/healthtest`);
};

export const loginUser = async (email: string, password: string) => {
  try {
    return await axios({
      method: 'put',
      url: `${API_URL}/login`,
      data: { email, password }
    });
  } catch (error) {
    return error;
  }
};

export const loginAdmin = async (email: string, password: string) => {
  try {
    return await axios({
      method: 'put',
      url: `${API_URL}/admin/login`,
      data: { email, password }
    });
  } catch (error) {
    return error;
  }
};

export const getTester = async (id: string, authHeader: any) => {
  try {
    return await axios({
      method: 'get',
      url: `${API_URL}/testers/${id}`,
      headers: authHeader
    });
  } catch (error) {
    return error;
  }
};

export const getAdmin = async (id: string, authHeader: any) => {
  try {
    return await axios({
      method: 'get',
      url: `${API_URL}/admins/${id}`,
      headers: authHeader
    });
  } catch (error) {
    return error;
  }
};

export const createTester = (email: string, password: string, display_name: string): any => {
  return axios({
    method: 'post',
    url: `${API_URL}/testers`,
    data: { email, password, display_name }
  });
};

export const getAllTesters = (authHeader: any) => {
  return axios({
    method: 'get',
    url: `${API_URL}/testers`,
    headers: authHeader
  });
};

export const requestToggleTesterIsActive = (id: number, currentIsActive: boolean, authHeader: any) => {
  return axios({
    method: 'PUT',
    url: `${API_URL}/testers/${id}/status`,
    headers: authHeader,
    data: {
      isEnabled: !currentIsActive
    }
  });
};

export const createAdmin = (email: string, display_name: string, password: string, authHeader: any) => {
  return axios({
    method: 'post',
    url: `${API_URL}/admins`,
    headers: authHeader,
    data: { email, display_name, password }
  });
};

export const updateAdminPassword = (id: number, new_password: string, authHeader: any) => {
  return axios({ method: 'put', url: `${API_URL}/admins/${id}/password`, headers: authHeader, data: { new_password } });
};

export const updateTesterPassword = (id: number, new_password: string, authHeader: any) => {
  return axios({ method: 'put', url: `${API_URL}/testers/${id}/password`, headers: authHeader, data: { new_password } });
};

export const getAllAdmins = (authHeader: any) => {
  return axios({
    method: 'get',
    url: `${API_URL}/admins`,
    headers: authHeader
  });
};

export const requestToggleAdminIsActive = (id: number, currentIsActive: boolean, authHeader: any) => {
  return axios({
    method: 'PUT',
    url: `${API_URL}/admins/${id}/status`,
    headers: authHeader,
    data: {
      isEnabled: !currentIsActive
    }
  });
};

export const getTestReleases = (authHeader: any, isAdmin: boolean) => {
  if (isAdmin) {
    return axios({ method: 'get', url: `${API_URL}/test-releases/admin`, headers: authHeader });
  } else {
    return axios({ method: 'get', url: `${API_URL}/test-releases/`, headers: authHeader });
  }
};

export const updateTestReleases = (id: number, body: { name: string; link: string }, authHeader: any) => {
  return axios({
    method: 'PUT',
    url: `${API_URL}/test-releases/${id}`,
    headers: authHeader,
    data: { ...body }
  });
};

export const updateMajorReleases = (id: number, body: { version: string[]; link: string }, authHeader: any) => {
  return axios({
    method: 'PUT',
    url: `${API_URL}/major-releases/${id}`,
    headers: authHeader,
    data: { ...body }
  });
};

export const getMajorReleases = (authHeader: any, isAdmin: boolean) => {
  if (isAdmin) {
    return axios({ method: 'get', url: `${API_URL}/major-releases/admin`, headers: authHeader });
  } else {
    return axios({ method: 'get', url: `${API_URL}/major-releases/`, headers: authHeader });
  }
};

export const deleteTestRelease = (id: number, authHeader: any) => {
  return axios({ method: 'delete', url: `${API_URL}/test-releases/${id}`, headers: authHeader });
};

export const deleteMajorRelease = (id: number, authHeader: any) => {
  return axios({ method: 'delete', url: `${API_URL}/major-releases/${id}`, headers: authHeader });
};

export const createTestRelease = (body: { name: string; link: string; uploaderId: string }, authHeader: any) => {
  return axios({
    method: 'post',
    url: `${API_URL}/test-releases/`,
    headers: authHeader,
    data: { ...body }
  });
};

export const createMajorRelease = (body: { version: number[]; link: string; uploaderId: string }, authHeader: any) => {
  return axios({
    method: 'post',
    url: `${API_URL}/major-releases/`,
    headers: authHeader,
    data: { ...body }
  }).then((res) => {
    return res;
  });
};

export const getAllBugReports = (authHeader: any, isAdmin: boolean) => {
  if (isAdmin) {
    return axios({
      method: 'get',
      url: `${API_URL}/bug-reports/admin`,
      headers: authHeader
    });
  } else {
    return axios({
      method: 'get',
      url: `${API_URL}/bug-reports/`,
      headers: authHeader
    });
  }
};

export const getBugReportById = (id: number, authHeader: any, isAdmin: boolean) => {
  if (isAdmin) {
    return axios({ method: 'get', url: `${API_URL}/bug-reports/${id}/admin`, headers: authHeader });
  } else {
    return axios({ method: 'get', url: `${API_URL}/bug-reports/${id}`, headers: authHeader });
  }
};

export const updateBugReportStatus = (id: number, newStatus: any, severity: any, priority: any, authHeader: any) => {
  return axios({ method: 'put', url: `${API_URL}/bug-reports/${id}/status`, headers: authHeader, data: { newStatus, severity, priority } });
};
